import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


function HomePage() {
    const [value, setValue] = React.useState(0);

    return (
        <Card sx={{ minWidth: 275 }} variant="outlined">
            <CardContent>
                <Typography variant="h5" component="div">
                    Home
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small">Learn More</Button>
            </CardActions>
        </Card>
    );
}

export default HomePage;